import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import url from "../ServerConnection/config";
import { toast } from "react-toastify";
import Loader from "./Loader";
import styled from "styled-components";
import ScrollToTop from "../ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../App/cart-slice";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import Spinner from "react-bootstrap/Spinner";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import RefreshToken from "./RefreshToken";
import { Breadcrumb } from "antd";

function SingleProduct() {
	const params = useParams();
	const [product, setProduct] = useState({});
	const [reviews, setReviews] = useState([]);
	const dispatch = useDispatch();
	let userId = useSelector((state) => state.user.userId);
	let accessToken = useSelector((state) => state.user.accessToken);
	const [loadRefreshComponent, setLoadRefreshComponent] = useState(false);
	const [rating, setRating] = useState(0);
	const [newReview, setNewReview] = useState("");
	const [editReview, setEditReview] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const onOpenEditModal = () => setOpenEdit(true);
	const onCloseEditModal = () => setOpenEdit(false);
	const errorNotification = (e) =>
		toast.error(e, {
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});
	const reviewSuccessNotification = (e) =>
		toast.success(e, {
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.TOP_CENTER,
			className: "add-success-notification",
		});
	const addNotification = (name) =>
		toast.success("Added " + name + " to cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "add-success-notification",
		});
	const addToCart = (name, id, price, image, sku) => {
		dispatch(
			cartActions.addToCart({
				name: name,
				id: id,
				price: price,
				image: image,
				sku: sku,
			})
		);
		addNotification(name);
	};
	const stockQuantityFn = () => {
		if (product.stockQuantity === 0) {
			return (
				<>
					<p style={{ color: "#e74c3c", fontSize: "large" }}>
						<strong>Out of Stock!</strong>
					</p>
				</>
			);
		} else if (product.stockQuantity >= 1 && product.stockQuantity <= 5) {
			return (
				<>
					<p style={{ color: "#e74c3c", fontSize: "large" }}>
						<strong>Only {product.stockQuantity} remaining!</strong>
					</p>
					<button
						onClick={() => {
							addToCart(
								product.name,
								product.id,
								product.price,
								product.image,
								product.sku
							);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		} else {
			return (
				<>
					<p style={{ color: "#07bc0c", fontSize: "large" }}>
						<strong>In Stock</strong>
					</p>
					<button
						onClick={() => {
							addToCart(
								product.name,
								product.id,
								product.price,
								product.image,
								product.sku
							);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		}
	};
	const ratingCalculation = () => {
		let sum = 0;
		for (let i = 0; i < reviews?.length; i++) {
			sum += reviews[i].rating;
		}
		if (reviews.length > 0) {
			return sum / reviews.length;
		} else {
			return 0;
		}
	};

	const handleAddReview = () => {
		!userId
			? errorNotification("Please login to add a review")
			: reviews?.some((review) => review.userId === userId)
			? errorNotification("You have already added a review")
			: onOpenModal();
	};

	const handleSubmitReview = () => {
		if (!newReview || rating === 0) {
			errorNotification("Please add both a rating and a review");
		} else {
			setSubmitting(true);
			axios
				.post(
					`${url}/reviews/add`,
					{
						userId: userId,
						productId: product.id,
						rating: Number(rating),
						review: newReview,
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then((res) => {
					reviewSuccessNotification("Review added successfully");
					setReviews(res.data);
					setNewReview("");
					setRating(0);
					setSubmitting(false);
					onCloseModal();
				})
				.catch((err) => {
					err.response.status === 401
						? setLoadRefreshComponent(true)
						: errorNotification(err.response.data);
					setSubmitting(false);
					onCloseModal();
				});
		}
	};

	const handleEditReview = (id) => {
		if (editReview === "" || rating === 0) {
			errorNotification("Please edit both your review and rating");
			return;
		} else {
			setSubmitting(true);
			axios
				.put(
					`${url}/reviews/edit`,
					{
						id: id,
						rating: Number(rating),
						review: editReview,
						userId: userId,
						productId: product.id,
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then((res) => {
					reviewSuccessNotification("Review edited successfully");
					setReviews(res.data);
					setSubmitting(false);
					setEditReview("");
					setRating(0);
					onCloseEditModal();
				})
				.catch((err) => {
					err.response.status === 401
						? setLoadRefreshComponent(true)
						: errorNotification(err.response.data);
					setSubmitting(false);
				});
		}
	};

	const handleDeleteReview = (id) => {
		setSubmitting(true);
		axios
			.post(
				`${url}/reviews/delete`,
				{
					id: id,
					userId: userId,
					productId: product.id,
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
			.then((res) => {
				reviewSuccessNotification("Review deleted successfully");
				setReviews(res.data);
				setSubmitting(false);
				setDeleteModal(false);
			})
			.catch((err) => {
				err.response.status === 401
					? setLoadRefreshComponent(true)
					: errorNotification(err.response.data);
				setSubmitting(false);
			});
	};

	useEffect(() => {
		axios
			.get(`${url}/products/${params.id}`)
			.then((res) => {
				setProduct(res.data.product);
				setReviews(res.data.reviews);
			})
			.catch((err) => {
				errorNotification(err.response.data);
			});
	}, [accessToken, params.id]);

	if (Object.keys(product).length === 0) {
		return (
			<SingleProductContainer>
				<Loader />
				<ScrollToTop />
			</SingleProductContainer>
		);
	} else {
		return (
			<SingleProductContainer>
				<Breadcrumb className="breadcrumb">
					<Breadcrumb.Item>
						<Link to="/">Home</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={"/category/" + product.category}>{product.category}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{product.name}</Breadcrumb.Item>
				</Breadcrumb>
				<ImgContainer>
					<img src={product.image} alt={product.name} />
				</ImgContainer>
				<DetailsContainer>
					<h3>
						<strong>{product.name}</strong>
					</h3>
					<p>
						<strong>KES {product.price.toLocaleString()}</strong>
					</p>
					<p>{product.description}</p>
					<p>Warranty: {product.warranty}</p>
					<p style={{ display: "flex", alignItems: "center" }}>
						Reviews ({reviews?.length})
						<Rating
							initialValue={ratingCalculation()}
							size={20}
							readonly
							style={{ marginLeft: "10px", fontSize: "small" }}
						/>
						<span
							style={{
								marginLeft: "10px",
								color: "#07bc0c",
								fontSize: "large",
								cursor: "pointer",
							}}
							onClick={handleAddReview}
						>
							Add a review
						</span>
					</p>
					<p>{stockQuantityFn()}</p>
				</DetailsContainer>
				{reviews?.length > 0 && (
					<ReviewsDiv>
						<h3>Reviews</h3>
						{reviews?.map((review) => {
							return (
								<div key={review.id}>
									<p style={{ marginTop: "10px" }}>
										<strong style={{ fontSize: "large" }}>
											{review.User.firstName + " " + review.User.lastName}{" "}
										</strong>{" "}
										on {moment(review.updatedAt).format("MMMM DD, YYYY")}
									</p>
									<Rating
										initialValue={review.rating}
										size={20}
										readonly
										allowHalfIcon={false}
										style={{ fontSize: "small", marginLeft: "20px" }}
									/>
									<p style={{ marginLeft: "20px" }}>{review.reviews}</p>
									{review.userId === userId ? (
										<p
											style={{
												marginLeft: "20px",
												display: "flex",
												gap: "20px",
											}}
										>
											<button
												onClick={() => {
													setEditReview(review.reviews);
													onOpenEditModal();
												}}
												style={{ border: "none", color: "#07bc0c" }}
											>
												<FaEdit /> Edit
											</button>
											<button
												onClick={() => {
													setDeleteModal(true);
												}}
												style={{ border: "none", color: "#e74c3c" }}
											>
												<MdDelete /> Delete
											</button>
										</p>
									) : null}
									{editReview !== "" ? (
										<Modal
											open={openEdit}
											onClose={onCloseEditModal}
											showCloseIcon={false}
											center
											styles={{ modal: { width: "70%" } }}
										>
											<h4>Edit review of {product?.name}</h4>
											<Rating
												initialValue={review.rating}
												onClick={(rating) => setRating(rating)}
												size={20}
											/>
											<textarea
												value={editReview}
												maxLength={1000}
												style={{
													width: "100%",
													height: "150px",
													marginTop: "20px",
													padding: "10px",
												}}
												onChange={(e) => setEditReview(e.target.value)}
											/>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													marginTop: "10px",
												}}
											>
												<button
													onClick={() => {
														onCloseEditModal();
														setEditReview("");
														setRating(0);
													}}
													style={{
														outline: "none",
														backgroundColor: "rgb(204, 204, 204)",
														border: "none",
														color: "#000",
														padding: "5px 10px",
														borderRadius: "5px",
														cursor: "pointer",
													}}
												>
													Cancel
												</button>
												<button
													onClick={() => {
														handleEditReview(review.id);
													}}
													style={{
														outline: "none",
														backgroundColor: "#0090ff",
														border: "none",
														color: "#fff",
														padding: "5px 10px",
														borderRadius: "5px",
														cursor: "pointer",
														width: "100px",
													}}
												>
													{submitting ? (
														<Spinner
															animation="border"
															variant="light"
															size="sm"
														/>
													) : (
														"Submit"
													)}
												</button>
											</div>
										</Modal>
									) : null}
									{deleteModal ? (
										<Modal
											open={deleteModal}
											onClose={onCloseEditModal}
											showCloseIcon={false}
											center
											styles={{
												modal: { width: "300px" },
											}}
										>
											<h4 style={{ textAlign: "center", color: "#e74c3c" }}>
												Confirm Delete Review of {product?.name}
											</h4>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													marginTop: "50px",
												}}
											>
												<button
													onClick={() => {
														setDeleteModal(false);
													}}
													style={{
														outline: "none",
														backgroundColor: "#0090ff",
														border: "none",
														color: "#fff",
														padding: "5px 10px",
														borderRadius: "5px",
														cursor: "pointer",
														width: "70px",
													}}
												>
													Cancel
												</button>
												<button
													onClick={() => {
														handleDeleteReview(review.id);
													}}
													style={{
														outline: "none",
														backgroundColor: "#e74c3c",
														border: "none",
														color: "#fff",
														padding: "5px 10px",
														borderRadius: "5px",
														cursor: "pointer",
														width: "70px",
													}}
												>
													{submitting ? (
														<Spinner
															animation="border"
															variant="light"
															size="sm"
														/>
													) : (
														"Delete"
													)}
												</button>
											</div>
										</Modal>
									) : null}
								</div>
							);
						})}
					</ReviewsDiv>
				)}
				{open && (
					<Modal
						open={open}
						onClose={onCloseModal}
						showCloseIcon={false}
						center
						styles={{ modal: { width: "70%" } }}
					>
						<h4>Add a review of {product?.name}</h4>
						<Rating
							initialValue={rating}
							onClick={(rating) => setRating(rating)}
							size={20}
						/>
						<textarea
							value={newReview}
							maxLength={1000}
							style={{
								width: "100%",
								height: "150px",
								marginTop: "20px",
								padding: "10px",
							}}
							onChange={(e) => setNewReview(e.target.value)}
						/>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "10px",
							}}
						>
							<button
								onClick={() => {
									onCloseModal();
									setNewReview("");
									setRating(0);
								}}
								style={{
									outline: "none",
									backgroundColor: "rgb(204, 204, 204)",
									border: "none",
									color: "#000",
									padding: "5px 10px",
									borderRadius: "5px",
									cursor: "pointer",
								}}
							>
								Cancel
							</button>
							<button
								onClick={handleSubmitReview}
								style={{
									outline: "none",
									backgroundColor: "#0090ff",
									border: "none",
									color: "#fff",
									padding: "5px 10px",
									borderRadius: "5px",
									cursor: "pointer",
									width: "100px",
								}}
							>
								{submitting ? (
									<Spinner animation="border" variant="light" size="sm" />
								) : (
									"Submit"
								)}
							</button>
						</div>
					</Modal>
				)}
				{loadRefreshComponent ? (
					<RefreshToken setLoadRefreshComponent={setLoadRefreshComponent} />
				) : null}
				<ScrollToTop />
			</SingleProductContainer>
		);
	}
}

const SingleProductContainer = styled.div`
	min-height: 80vh;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 10px;
	flex-wrap: wrap;
	.breadcrumb {
		width: 100%;
		margin-left: 30px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		.breadcrumb {
			margin-left: 10px;
		}
	}
`;

const ImgContainer = styled.div`
	flex: 1 1 60%;
	padding: 20px 30px 10px 30px;
	img {
		margin: auto;
		object-fit: contain;
		width: 80%;
		height: auto;
		max-height: 60vh;
		transition: 450ms;
	}
	img:hover {
		transform: scale(1.03);
	}
	@media (max-width: 768px) {
		padding: 20px 30px 0 30px;
		margin: auto;
		flex: 1 1 100%;
		img {
			width: 100%;
		}
	}
`;

const DetailsContainer = styled.div`
	flex: 1 1 30%;
	padding: 20px 30px 10px 30px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	h3 {
		font-size: large;
	}
	p {
		user-select: none;
	}
	button {
		background-color: rgba(20, 20, 20, 0.5);
		border: 1px solid;
		border-color: yellow yellowgreen yellowgreen yellow;
		color: var(--primary-color);
		cursor: pointer;
		width: 100%;
		height: 30px;
		transition: 450ms;
		margin-top: 5px;
	}
	button:hover {
		transform: scale(1.05);
	}
	@media (max-width: 768px) {
		padding: 10px 30px 0 30px;
		text-align: center;
		flex: 1 1 100%;
		p {
			justify-content: center;
		}
		button {
			width: 50%;
		}
	}
	@media (max-width: 450px) {
		button {
			width: 100%;
		}
	}
`;

const ReviewsDiv = styled.div`
	flex: 1 1 100%;
	background-color: #ededed;
	padding: 20px 30px 10px 30px;
	div,
	h3 {
		margin: 10px auto 10px auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-left: 30%;
	}
	p {
		margin: 0;
		padding: 0;
	}
	@media (max-width: 768px) {
		div,
		h3 {
			padding-left: 20px;
		}
	}
`;

export default SingleProduct;

import React from "react";
import Banner from "./Banner";
import ScrollToTop from "../ScrollToTop";
import styled from "styled-components";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

function Policy() {
	return (
		<div>
			<BreadcrumbDiv>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to="/">Home</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						Shippings, Returns, & Privacy Policy
					</Breadcrumb.Item>
				</Breadcrumb>
			</BreadcrumbDiv>
			<Banner />
			<PolicyDiv>
				<h3>
					<strong>Delivery</strong>
				</h3>
				<p>
					We at Hush Computing promise to do our best to provide you with an
					efficient and hassle-free delivery process. We have taken and will
					continue to undertake necessary precautions to ensure your products
					are delivered to you safely.
				</p>
				<h5>Delivery Timelines</h5>
				<p>
					<span>Nairobi and its outskirts:</span>
					<span>0-2 business days from order confirmation</span>
				</p>
				<p>
					<span>Rest of the country:</span>
					<span>3-6 business days from order confirmation</span>
				</p>
				<p>
					*Delivery will only be made after we have verified the order with you
					over the phone.
				</p>
				<p>
					Delivery charges are based on the cumulative weight of your order and
					the delivery location (Ranging from KES 200 to max KES 3,000). Free
					delivery for items to be delivered within Nairobi CBD.
				</p>
				<h5>Returns and Refunds Policy</h5>
				<p>
					We offer returns/replacement within 3 days of delivery, where the
					product:
				</p>
				<ul>
					<li>is faulty</li>
					<li>delivered is not what was ordered</li>
					<li>
						is proven to be dead on arrival (not working due to factory fault or
						other vendor error)
					</li>
				</ul>
				<p>
					To be eligible for a return, your item must be unused and in the same
					condition that you received it. It must also be in the original
					packaging.
				</p>
				<p>
					To request a return/replacement, kindly contact 0719738483 or
					muchinaelias@gmail and keep your order details at hand.
				</p>
				<p>
					In the event of a replacement, Hush Computing will bear the delivery
					cost for the replacement portion. We will need to collect the faulty
					item(s), inspect the item(s) and advise you accordingly.
				</p>
				<p>
					If Hush Computing is unable to replace an item within 7 business days
					from the return date, we will refund you the total value paid in full,
					inclusive of all delivery charges.
				</p>
				<h5>Warranty</h5>
				<p>
					All products we sell come with a warranty. Goods under warranty are
					subject to terms and conditions relating to the purchased item.{" "}
				</p>
				<h5>Privacy</h5>
				<p>
					Hush Computing is committed to providing quality services to you and
					this policy outlines our ongoing obligations to you in respect of how
					we manage your personal information. Hush Computing has adopted the
					Kenyan Privacy Principles contained in the The Computer and
					Cybercrimes Act 2018. The Act governs the way we collect, use,
					disclose, store, secure and dispose off your personal information.
				</p>
				<p>
					<strong>
						What is personal information and why do we collect it?
					</strong>
				</p>
				<p>
					Personal information is information or an opinion that identifies an
					individual. Examples of personal information we collect include:
					names, addresses, email addresses, phone, town/city, and general
					information required for shipping. We collect your personal
					information for the primary purpose of providing our services to you,
					providing information to our clients and marketing. We may also use
					your personal information for secondary purposes closely related to
					the primary purpose, in circumstances where you would reasonably
					expect such use or disclosure. You may unsubscribe from our
					mailing/marketing lists at any time. We also collect this information
					for shipping purposes, i.e.:
				</p>
				<ul>
					<li>process orders</li>
					<li>deliver products and services</li>
					<li>
						process payments and communicate with you about your orders,
						products, services and promotional offers
					</li>
					<li>keep and update our database and your accounts with us</li>
					<li>prevent and detect fraud and abuse on our website</li>
				</ul>
				<p>
					Your personal information is stored in a manner that reasonably
					protects it from misuse, loss and from unauthorized access,
					modification or disclosure. When your personal information is no
					longer needed for the purpose for which it was obtained, we will take
					reasonable steps to destroy or permanently de-identify your it.
					However, most of the personal information is or will be stored in a
					client database which will be kept by us for a minimum of 2 years.
				</p>
				<p>
					You may access the personal information we hold about you to update
					and/or correct it. If you experience any challenges, kindly contact
					us.
				</p>
			</PolicyDiv>
			<ScrollToTop />
		</div>
	);
}

const BreadcrumbDiv = styled.div`
	margin-top: 10px;
	padding: 0 30px 0 30px;
	@media (max-width: 650px) {
		padding: 0 10px 0 10px;
	}
`;

const PolicyDiv = styled.div`
	margin-top: 15px;
	padding: 0 30px 0 30px;
	p {
		display: flex;
		span {
			:nth-child(1) {
				width: 200px;
			}
			:nth-child(2) {
				margin-left: 10px;
			}
		}
	}
	@media (max-width: 650px) {
		padding: 0 10px 0 10px;
	}
`;

export default Policy;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { cartActions } from "../App/cart-slice";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { toast } from "react-toastify";
import { Tooltip } from "antd";

function CartItem() {
	const cartItems = useSelector((state) => state.cart.itemsList);
	const totalPrice = useSelector((state) => state.cart.totalPrice);

	const dispatch = useDispatch();

	const addNotification = (name) =>
		toast.success("Added quantity of " + name + " to cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "add-success-notification",
		});

	const removeNotification = (name) =>
		toast.error("Removed " + name + " from cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "remove-success-notification",
		});
	const reduceNotification = (name) =>
		toast.error("Reduced " + name + " from cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "remove-success-notification",
		});

	const removeItem = (name, id, totalItemPrice) => {
		dispatch(
			cartActions.removeFromCart({
				id: id,
				totalItemPrice: totalItemPrice,
			})
		);
		removeNotification(name);
	};

	const addToCart = (name, id, price, image) => {
		dispatch(
			cartActions.addToCart({
				name: name,
				id: id,
				price: price,
				image: image,
			})
		);
		addNotification(name);
	};

	const reduceFromCart = (name, id, price, quantity) => {
		dispatch(
			cartActions.reduceFromCart({
				id: id,
				price: price,
			})
		);
		quantity > 1 && reduceNotification(name);
	};

	return (
		<div>
			{cartItems.length !== 0 &&
				cartItems.map((item) => (
					<Cart key={item.id}>
						<Tooltip title="Remove Item" color="#e74c3c" placement="bottom">
							<section
								onClick={() =>
									removeItem(item.name, item.id, item.totalItemPrice)
								}
							>
								<Bin />
							</section>
						</Tooltip>
						<img src={item.image} alt="" />
						<p>
							<strong>{item.name}</strong>
						</p>
						<span>KES {item.price.toLocaleString()}</span>
						<Quantity>
							{item.quantity}
							<Arrows>
								<Tooltip title="Add Quantity" color="#07bc0c" placement="top">
									<p>
										<IoIosArrowUp
											onClick={() =>
												addToCart(item.name, item.id, item.price, item.image)
											}
										/>
									</p>
								</Tooltip>
								<Tooltip
									title="Reduce Quantity"
									color="#e74c3c"
									placement="bottom"
								>
									<p>
										<IoIosArrowDown
											onClick={() =>
												reduceFromCart(
													item.name,
													item.id,
													item.price,
													item.quantity
												)
											}
										/>
									</p>
								</Tooltip>
							</Arrows>
						</Quantity>
						<span>KES {item.totalItemPrice.toLocaleString()}</span>
					</Cart>
				))}
			<Total>
				<span>
					<strong>Total</strong>
				</span>
				<span>
					<strong>KES {totalPrice.toLocaleString()}</strong>
				</span>
			</Total>
		</div>
	);
}

const Cart = styled.div`
	display: flex;
	gap: 5px;
	margin-bottom: 20px;
	text-align: center;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 10px;
	img {
		width: 25%;
		height: 70px;
		object-fit: contain;
		@media (max-width: 768px) {
			height: 60px;
		}
		@media (max-width: 576px) {
			height: 50px;
		}
	}
	p {
		width: 20%;
		font-size: small;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 0.7rem;
			word-wrap: break-word;
		}
		@media (max-width: 576px) {
			font-size: 0.6rem;
		}
	}
	span {
		width: 16%;
		font-size: small;
		@media (max-width: 768px) {
			font-size: 0.7rem;
		}
		@media (max-width: 576px) {
			font-size: 0.6rem;
		}
	}
	section {
		width: 5%;
	}
`;

const Total = styled.div`
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin-top: 10px;
	align-items: center;
	span {
		width: 20%;
	}
	@media (max-width: 768px) {
		font-size: 0.7rem;
	}
`;

const Bin = styled(RiDeleteBin5Line)`
	color: red;
	font-size: medium;
	cursor: pointer;
	@media (max-width: 768px) {
		font-size: small;
	}
`;

const Quantity = styled.span`
	display: flex;
	justify-content: center;
	gap: 3px;
	align-items: center;
	overflow: hidden;
`;

const Arrows = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 50%;
	height: 50%;
	padding: 0;
	p {
		margin: 0;
		padding: 0;
		font-size: smaller;
		:nth-child(1) {
			width: 100%;
			color: #418914;
		}
		:nth-child(2) {
			width: 100%;
			color: #e74c3c;
		}
	}
`;

export default CartItem;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../index.css";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "./CartItem";
import { cartActions } from "../App/cart-slice";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { MdRemoveShoppingCart } from "react-icons/md";
import { BsPerson, BsTelephone } from "react-icons/bs";
import { GiModernCity } from "react-icons/gi";
import { FaRegAddressBook } from "react-icons/fa";
import { toast } from "react-toastify";
import ScrollToTop from "../ScrollToTop";
import axios from "axios";
import url from "../ServerConnection/config";
import RefreshToken from "./RefreshToken";
import { Modal } from "react-responsive-modal";
import Spinner from "react-bootstrap/Spinner";

function Checkout() {
	const cartItems = useSelector((state) => state.cart.itemsList);
	const totalPrice = useSelector((state) => state.cart.totalPrice);
	const dispatch = useDispatch();
	const userId = useSelector((state) => state.user.userId);
	const accessToken = useSelector((state) => state.user.accessToken);
	const [loadRefreshComponent, setLoadRefreshComponent] = useState(false);
	const [userDetails, setUserDetails] = useState({});
	const [orderModal, setOrderModal] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const toastId = "toast-id";
	const navigate = useNavigate();

	const onCloseModal = () => {
		setOrderModal(false);
	};

	useEffect(() => {
		if (userId !== null) {
			axios
				.post(
					`${url}/user/userdetails`,
					{ userId: userId },
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then((res) => {
					setUserDetails(res.data);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						setLoadRefreshComponent(true);
					}
				});
		}
	}, [accessToken, userId]);

	const clearCartNotification = () =>
		toast.error("Cleared the cart!", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "remove-success-notification",
		});

	const errorNotification = (e) =>
		toast.error(e, {
			toastId: toastId,
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});

	const successNotification = (e) =>
		toast.success(e, {
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.TOP_CENTER,
			className: "add-success-notification",
		});

	const clearCart = () => {
		dispatch(cartActions.clearFromCart({}));
		clearCartNotification();
	};

	const handleContactInformation = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const email = formData.get("email");
		const firstName = formData.get("firstName");
		const lastName = formData.get("lastName");
		const address = formData.get("address");
		const city = formData.get("city");
		const mobileNumber = formData.get("mobileNumber");
		if (
			!email ||
			!firstName ||
			!lastName ||
			!address ||
			!city ||
			!mobileNumber
		) {
			errorNotification("Please fill all the fields!");
			return;
		} else if (mobileNumber.length < 10 || mobileNumber.length > 13) {
			errorNotification("Please enter a valid mobile number!");
			return;
		} else if (
			firstName !== userDetails?.firstName ||
			lastName !== userDetails?.lastName ||
			address !== userDetails?.address ||
			city !== userDetails?.city ||
			mobileNumber !== userDetails?.mobileNumber
		) {
			axios
				.post(
					`${url}/user/updateuserdetails`,
					{
						userId: userId,
						email: email,
						firstName: firstName,
						lastName: lastName,
						address: address,
						city: city,
						mobileNumber: mobileNumber,
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then((res) => {
					setUserDetails(res.data);
					successNotification("Successfully saved your details!");
				})
				.catch((err) => {
					if (err.response.status === 401) {
						setLoadRefreshComponent(true);
					} else {
						errorNotification(err.response.data);
					}
				});
		}
	};

	const handlePlaceOrder = () => {
		if (
			!userDetails?.firstName ||
			!userDetails?.lastName ||
			!userDetails?.address ||
			!userDetails?.city ||
			!userDetails?.mobileNumber
		) {
			errorNotification("Please fill all delivery details and save!");
			return;
		} else {
			setOrderModal(true);
		}
	};

	const handleSubmitOrder = () => {
		setSubmitting(true);
		axios
			.post(
				`${url}/orders/create`,
				{
					userId: userId,
					userAddress: userDetails?.address,
					userCity: userDetails?.city,
					total: totalPrice,
					orderItems: cartItems,
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
			.then((res) => {
				setSubmitting(false);
				successNotification(`Successfully placed order number ${res.data.id}`);
				dispatch(cartActions.clearFromCart({}));
				setOrderModal(false);
				navigate("/account");
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setLoadRefreshComponent(true);
				} else {
					errorNotification(err.response.data);
					setSubmitting(false);
				}
			});
	};

	return (
		<Wrapper
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 1 } }}
			transition={{ duration: 1.5 }}
		>
			<CartContainer>
				{cartItems.length !== 0 && (
					<>
						<Cart>
							<p>
								<button onClick={clearCart}>
									<MdRemoveShoppingCart />
									Clear Cart
								</button>
							</p>
							<p></p>
							<span>Unit Price</span>
							<span>Quantity</span>
							<span>Total Price</span>
						</Cart>
						<CartItem />
					</>
				)}
				{cartItems.length === 0 && (
					<OopsMsg>
						Oops, You have no item in your cart!{" "}
						<StyledLink to="/" tabIndex="-1">
							Click here{" "}
						</StyledLink>
						to browse our catalogue.
					</OopsMsg>
				)}
			</CartContainer>
			<RightContainer>
				{Object.keys(userDetails).length > 0 && (
					<>
						<form onSubmit={handleContactInformation}>
							<h5>Delivery Details</h5>
							<input
								type="email"
								placeholder="Email"
								value={userDetails?.email}
								name="email"
								readOnly
							/>
							<section>
								<input
									type="text"
									placeholder="First Name"
									defaultValue={userDetails?.firstName}
									name="firstName"
									required
								/>
								<input
									type="text"
									placeholder="Last Name"
									defaultValue={userDetails?.lastName}
									name="lastName"
									required
								/>
							</section>
							<input
								type="text"
								placeholder="Phone"
								defaultValue={userDetails?.mobileNumber}
								name="mobileNumber"
								required
							/>
							<select>
								<option name="country" value="Kenya">
									Kenya
								</option>
							</select>
							<input
								type="text"
								placeholder="City/Town"
								defaultValue={userDetails?.city}
								name="city"
								required
							/>
							<input
								type="text"
								placeholder="Address"
								defaultValue={userDetails?.address}
								name="address"
								required
							/>
							<section>
								<span>
									Want to change delivery details? Enter all details and click
									on save button
								</span>
								<button type="submit">Save</button>
							</section>
						</form>
						{cartItems.length !== 0 && (
							<PlaceOrder>
								<button onClick={handlePlaceOrder}>Place Order</button>
							</PlaceOrder>
						)}
					</>
				)}
				{!userId && (
					<>
						<p>
							Have an account? <Link to={"/login"}>Login</Link> to proceed
						</p>
						<p>
							Don't have an account? <Link to={"/register"}>Register</Link> to
							proceed
						</p>
					</>
				)}
			</RightContainer>
			{loadRefreshComponent ? (
				<RefreshToken setLoadRefreshComponent={setLoadRefreshComponent} />
			) : null}
			<ScrollToTop />
			{orderModal && (
				<Modal
					open={orderModal}
					onClose={onCloseModal}
					showCloseIcon={false}
					center
					closeOnOverlayClick={false}
					styles={{ modal: { width: "90%" } }}
				>
					<h4>Verify Details and Confirm Order</h4>
					<ModalUserDetails>
						<span>
							<BsPerson style={{ marginRight: "5px" }} />
							{userDetails?.firstName + " " + userDetails?.lastName}
						</span>
						<span>
							<BsTelephone style={{ marginRight: "5px" }} />{" "}
							{userDetails?.mobileNumber}
						</span>
						<span>
							<GiModernCity style={{ marginRight: "5px" }} />{" "}
							{userDetails?.city}
						</span>
						<span>
							<FaRegAddressBook style={{ marginRight: "5px" }} />{" "}
							{userDetails?.address}
						</span>
					</ModalUserDetails>
					<OrderDetails>
						<p></p>
						<p></p>
						<span>Unit Price</span>
						<span>Quantity</span>
						<span>Total Price</span>
					</OrderDetails>
					{cartItems.map((item) => (
						<OrderDetails key={item.id}>
							<img src={item.image} alt="" />
							<p>
								<strong>{item.name}</strong>
							</p>
							<span>KES {item.price.toLocaleString()}</span>
							<span>{item.quantity}</span>
							<span>KES {item.totalItemPrice.toLocaleString()}</span>
						</OrderDetails>
					))}
					<OrderDetails
						style={{
							paddingTop: "5px",
							borderTop: "1px solid rgb(204, 204, 204)",
							alignItems: "flex-start",
						}}
					>
						<p></p>
						<p>
							<strong>Total</strong>
						</p>
						<span></span>
						<span></span>
						<span>
							<strong>KES {totalPrice.toLocaleString()}</strong>
						</span>
					</OrderDetails>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "20px",
						}}
					>
						<button
							onClick={() => {
								setOrderModal(false);
							}}
							style={{
								outline: "none",
								backgroundColor: "rgb(204, 204, 204)",
								border: "none",
								color: "#000",
								padding: "5px 10px",
								borderRadius: "5px",
								cursor: "pointer",
								height: "40px",
							}}
						>
							Cancel
						</button>
						<button
							onClick={handleSubmitOrder}
							style={{
								outline: "none",
								backgroundColor: "#e74c3c",
								border: "none",
								color: "#fff",
								padding: "5px 10px",
								borderRadius: "5px",
								cursor: "pointer",
								height: "40px",
							}}
						>
							{submitting ? (
								<Spinner animation="border" variant="light" size="sm" />
							) : (
								"Confirm Order"
							)}
						</button>
					</div>
					<p style={{ marginTop: "15px", fontSize: "15px" }}>
						By placing an order, you confirm that you have read and agreed with
						all terms and conditions in our{" "}
						<Link to="/policy">Shipping, Returns, and Privacy</Link> policy.
					</p>
				</Modal>
			)}
		</Wrapper>
	);
}

const Wrapper = styled(motion.div)`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	color: var(--secondary-color);
	box-sizing: border-box;
	min-height: 530px;

	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 85%;
		section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			input {
				width: 49%;
			}
		}
		input,
		select {
			width: 100%;
			outline: none;
			transition: all 0.25s ease-in-out;
			-webkit-transition: all 0.25s ease-in-out;
			-moz-transition: all 0.25s ease-in-out;
			border-radius: 3px;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border: 1px solid rgba(0, 0, 0, 0.2);
		}
		input:focus,
		select:focus {
			box-shadow: 0 0 5px rgba(118, 118, 118, 0.4);
			-webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.4);
			-moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.4);
			border: 1px solid rgba(118, 118, 118, 0.4);
		}
		span {
			color: #e74c3c;
		}
		button {
			background-color: #418914;
			color: white;
			border: 1px solid;
			border-color: #418914;
			border-radius: 3px;
			transition: 450ms;
			outline: none;
			width: 50%;
			height: 40px;
		}
		button:hover,
		button:focus {
			transform: scale(1.01);
		}
		@media (max-width: 768px) {
			margin-left: auto;
			margin-right: auto;
			font-size: small;
			h5 {
				font-size: small;
			}
		}
	}
	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		min-height: 100px;
	}
`;

const PlaceOrder = styled.div`
	margin-top: 100px;
	display: flex;
	justify-content: center;
	button {
		background-color: #e74c3c;
		border-radius: 3px;
		border: 1px solid #e74c3c;
		outline: none;
		color: #fff;
		width: 40%;
		height: 40px;
		transition: 450ms;
	}
	button:hover,
	button:focus {
		transform: scale(1.03);
	}
`;

const CartContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 30px 20px 30px;
	background-color: #f9f9f9;
`;

const OopsMsg = styled.span`
	@media (max-width: 768px) {
		font-size: small;
	}
`;

const StyledLink = styled(Link)`
	text-decoration: none;
`;

const Cart = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	text-align: center;
	margin-bottom: 20px;
	button {
		background-color: #e74c3c;
		border: #e74c3c;
		outline: none;
		color: white;
		border-radius: 3px;
		font-size: small;
		display: flex;
		align-items: center;
		gap: 3px;
		@media (max-width: 768px) {
			font-size: 0.53rem;
		}
	}
	p {
		width: 25%;
	}
	span {
		width: 16%;
		font-size: medium;
		@media (max-width: 768px) {
			font-size: 0.7rem;
		}
		@media (max-width: 576px) {
			word-wrap: break-word;
			font-size: 0.6rem;
			width: 14%;
		}
	}
`;

const RightContainer = styled.div`
	padding: 20px 30px 20px 30px;
	background-color: #ededed;
`;

const ModalUserDetails = styled.div`
	background-color: #afe1af;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 50px;
	font-size: medium;
	overflow: hidden;
	span {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		overflow: hidden;
		white-space: nowrap;
	}
	@media (min-width: 500px) and (max-width: 768px) {
		height: 80px;
		justify-content: flex-start;
		flex-direction: column;
		flex-wrap: wrap;
		span {
			flex: 1 1 50%;
			font-size: small;
			justify-content: flex-start;
			gap: 5px;
		}
	}
	@media (max-width: 499px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		height: 100px;
		span {
			font-size: smaller;
			flex: 1 1 100%;
			justify-content: flex-start;
			padding-left: 10px;
		}
	}
`;

const OrderDetails = styled.div`
	display: flex;
	gap: 5px;
	margin-top: 10px;
	align-items: center;
	p {
		width: 25%;
	}
	img {
		width: 25%;
		height: 70px;
		object-fit: contain;
	}
	span {
		width: 16%;
	}
	@media (max-width: 499px) {
		img {
			width: 20%;
		}
		p {
			width: 20%;
			font-size: smaller;
		}
		span {
			font-size: smaller;
			text-align: center;
			:first-of-type {
				margin-left: 15px;
			}
		}
	}
`;

export default Checkout;

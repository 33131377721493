import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
	name: "cart",
	initialState: {
		itemsList: [],
		totalQuantity: 0,
		totalPrice: 0,
	},
	reducers: {
		addToCart(state, action) {
			const newItem = action.payload;
			const checkIfItemExists = state.itemsList.find(
				(item) => item.id === newItem.id
			);
			state.totalPrice += newItem.price;
			if (checkIfItemExists) {
				checkIfItemExists.quantity += 1;
				checkIfItemExists.totalItemPrice += newItem.price;
			} else {
				state.totalQuantity++;
				state.itemsList.push({
					id: newItem.id,
					price: newItem.price,
					name: newItem.name,
					image: newItem.image,
					sku: newItem.sku,
					quantity: 1,
					totalItemPrice: newItem.price,
				});
			}
		},
		removeFromCart(state, action) {
			const removeItem = action.payload;
			state.itemsList = state.itemsList.filter(
				(item) => item.id !== removeItem.id
			);
			state.totalQuantity--;
			state.totalPrice = state.totalPrice - removeItem.totalItemPrice;
		},
		reduceFromCart(state, action) {
			const reduceItem = action.payload;
			const checkIfItemExists = state.itemsList.find(
				(item) => item.id === reduceItem.id
			);
			if (checkIfItemExists.quantity > 1) {
				checkIfItemExists.quantity--;
				checkIfItemExists.totalItemPrice -= reduceItem.price;
				state.totalPrice -= reduceItem.price;
			} else {
				return;
			}
		},
		clearFromCart(state) {
			state.itemsList = [];
			state.totalQuantity = 0;
			state.totalPrice = 0;
		},
	},
});

export const cartActions = cartSlice.actions;

export default cartSlice;

import React, { useEffect, useState } from "react";
import logo from "../Images/Company/Hush Logo.png";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../index.css";
import { IoCreate } from "react-icons/io5";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { userActions } from "../App/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import bg from "../Images/photo-1546198632-9ef6368bef12.png";
import { toast } from "react-toastify";
import axios from "axios";
import url from "../ServerConnection/config";

function Register() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [passwordOne, setPasswordOne] = useState("");
	const [passwordTwo, setPasswordTwo] = useState("");

	const userId = useSelector((state) => state.user.userId);
	useEffect(() => {
		userId && navigate("/");
	}, [userId, navigate]);

	const toastId = React.useRef(null);

	const notify = () =>
		(toastId.current = toast.loading("Creating your account...", {
			autoClose: false,
			hideProgressBar: true,
			pauseOnHover: false,
			closeOnClick: true,
			className: "info-notification",
		}));

	const errorNotification = (e) =>
		toast.error(e, {
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});

	const [passwordTypeOne, setPasswordTypeOne] = useState("password");
	const togglePasswordOne = (e) => {
		e.preventDefault();
		if (passwordTypeOne === "password") {
			setPasswordTypeOne("text");
			setTimeout(() => {
				setPasswordTypeOne("password");
			}, 1500);
		} else {
			setPasswordTypeOne("password");
		}
	};

	const [passwordTypeTwo, setPasswordTypeTwo] = useState("password");
	const togglePasswordTwo = (e) => {
		e.preventDefault();
		if (passwordTypeTwo === "password") {
			setPasswordTypeTwo("text");
			setTimeout(() => {
				setPasswordTypeTwo("password");
			}, 1500);
		} else {
			setPasswordTypeTwo("password");
		}
	};

	const register = (e) => {
		e.preventDefault();
		if (passwordOne !== passwordTwo) {
			errorNotification("Passwords do not match!");
			return;
		} else if (passwordOne === "" || passwordTwo === "") {
			errorNotification("Enter a password!");
			return;
		} else {
			notify();
			axios
				.post(`${url}/user/register`, { email: email, password: passwordOne })
				.then((res) => {
					// Signed in
					toast.update(toastId.current, {
						render: "Welcome, " + email,
						type: toast.TYPE.SUCCESS,
						autoClose: 5000,
						isLoading: false,
						pauseOnFocusLoss: false,
						hideProgressBar: true,
						pauseOnHover: false,
						className: "success-notification",
					});
					const id = res.data.id;
					const accessToken = res.data.accessToken;
					const refreshToken = res.data.refreshToken;
					dispatch(
						userActions.login({
							id: id,
							email: email,
							accessToken: accessToken,
							refreshToken: refreshToken,
						})
					);
					navigate("/");
				})
				.catch((error) => {
					toast.update(toastId.current, {
						render: error.response.data,
						type: toast.TYPE.ERROR,
						autoClose: 5000,
						isLoading: false,
						pauseOnFocusLoss: false,
						hideProgressBar: true,
						pauseOnHover: false,
						className: "error-notification",
					});
				});
		}
	};

	return (
		<Page>
			<MainContainer
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0, transition: { duration: 1 } }}
				transition={{ duration: 1.5 }}
			>
				<LogoContainer>
					<TopLink to="/">
						<img src={logo} alt="" />
						<p>Hush Computing</p>
						<span>
							<em>More Tech, More Possibilities</em>
						</span>
					</TopLink>
				</LogoContainer>

				<LoginContainer>
					<p>
						<strong>Register to continue shopping on Hush Computing</strong>
					</p>
					<form autoComplete="off">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							value={email}
							autoComplete="new-password"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label htmlFor="password">Password</label>
						<div>
							<input
								type={passwordTypeOne}
								value={passwordOne}
								autoComplete="new-password"
								onChange={(e) => setPasswordOne(e.target.value)}
							/>
							<EyeIcon onClick={(e) => togglePasswordOne(e)} tabIndex="-1">
								{passwordTypeOne === "password" && passwordOne !== "" ? (
									<AiOutlineEye />
								) : (
									<AiOutlineEyeInvisible />
								)}
							</EyeIcon>
						</div>
						<label htmlFor="confirmpassword">Confirm Password</label>
						<div>
							<input
								type={passwordTypeTwo}
								value={passwordTwo}
								autoComplete="new-password"
								onChange={(e) => setPasswordTwo(e.target.value)}
							/>
							<EyeIcon onClick={(e) => togglePasswordTwo(e)} tabIndex="-1">
								{passwordTypeTwo === "password" && passwordTwo !== "" ? (
									<AiOutlineEye />
								) : (
									<AiOutlineEyeInvisible />
								)}
							</EyeIcon>
						</div>
						<SubmitBtn type="submit" onClick={register}>
							<IoCreate />
							<span>
								<small>Register</small>
							</span>
						</SubmitBtn>
						<p>
							By registering, you are agreeing to Hush Computing's Terms of
							Service.
						</p>
					</form>
					<BottomContainer>
						<p>
							Have an account?
							<StyledLink to="/login"> Login</StyledLink>
						</p>
					</BottomContainer>
				</LoginContainer>
			</MainContainer>
		</Page>
	);
}

const Page = styled.div`
	background: linear-gradient(-45deg, var(--secondary-color), #767676);
	height: 100vh;
	top: 0;
	width: 100vw;
	padding-top: 5vh;
`;

const MainContainer = styled(motion.div)`
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 90vh;
	width: 60%;
	margin: auto;
	@media (max-width: 768px) {
		flex-direction: column;
		width: 80%;
	}
`;

const LogoContainer = styled.div`
	margin-right: auto;
	margin-left: auto;
	color: var(--primary-color);
	flex: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #767676;
	border-right: none;
	background-image: url(${bg});
	background-repeat: no-repeat;
	background-size: cover;
	@media (max-width: 768px) {
		width: 100%;
		border: 1px solid #767676;
		border-bottom: none;
	}
`;

const TopLink = styled(Link)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: var(--primary-color);
	text-decoration: none;
	:hover {
		color: var(--primary-color);
	}
	img {
		height: 50px;
		width: 100%;
		object-fit: contain;
	}
	p {
		margin-bottom: 1px;
		font-size: large;
	}
	span {
		font-size: small;
		margin-top: 0;
	}
`;

const LoginContainer = styled.div`
	flex: 1;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	color: var(--primary-color);
	border: 1px solid #767676;
	padding: 15px;
	border: 1px solid #767676;
	border-left: none;
	padding: 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 768px) {
		width: 100%;
		border: 1px solid #767676;
		border-top: none;
		form {
			gap: 5px;
			font-size: smaller;
		}
		p {
			font-size: smaller;
		}
	}
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		input {
			width: 100%;
			position: relative;
			height: 30px;
			outline: none;
			border: none;
			color: #000;
		}
		p {
			font-size: small;
		}
	}
`;

const EyeIcon = styled.button`
	border: none;
	position: absolute;
	margin-left: -30px;
	max-height: 20px;
	background-color: transparent;
	color: #000;
`;

const SubmitBtn = styled.button`
	background-color: #418914;
	color: white;
	border: 1px solid;
	border-color: #418914;
	border-radius: 3px;
	transition: 450ms;
	margin-top: 10px;
	font-size: medium;
	vertical-align: middle;
	outline: none;
	:hover,
	:focus {
		transform: scale(1.05);
	}
`;

const BottomContainer = styled.div`
	font-size: small;
	p {
		margin-top: -10px;
	}
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	font-size: medium;
	@media (max-width: 576px) {
		font-size: small;
	}
`;

export default Register;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../index.css";
import { useDispatch } from "react-redux";
import { cartActions } from "../App/cart-slice";
import { toast } from "react-toastify";
import url from "../ServerConnection/config";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "./Loader";

function Products() {
	const dispatch = useDispatch();
	const [products, setProducts] = useState([]);

	useEffect(() => {
		axios
			.get(`${url}/products/home`)
			.then((res) => {
				setProducts(res.data);
			})
			.catch((err) => {});
	}, []);

	const addNotification = (name) =>
		toast.success("Added " + name + " to cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "add-success-notification",
		});

	const addToCart = (name, id, price, image, sku) => {
		dispatch(
			cartActions.addToCart({
				name: name,
				id: id,
				price: price,
				image: image,
				sku: sku,
			})
		);
		addNotification(name);
	};

	function truncate(string, n) {
		return string?.length > n ? string.substring(0, n - 1) + "..." : string;
	}
	const stockQuantityFn = (i) => {
		if (products[i].stockQuantity === 0) {
			return (
				<>
					<p style={{ color: "#e74c3c" }}>
						<strong>Out of Stock!</strong>
					</p>
				</>
			);
		} else if (
			products[i].stockQuantity >= 1 &&
			products[i].stockQuantity <= 5
		) {
			return (
				<>
					<p style={{ color: "#e74c3c" }}>
						<strong>Only {products[i].stockQuantity} remaining!</strong>
					</p>
					<button
						onClick={() => {
							addToCart(
								products[i].name,
								products[i].id,
								products[i].price,
								products[i].image,
								products[i].sku
							);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		} else {
			return (
				<>
					<p style={{ color: "#07bc0c" }}>
						<strong>In Stock</strong>
					</p>
					<button
						onClick={() => {
							addToCart(
								products[i].name,
								products[i].id,
								products[i].price,
								products[i].image,
								products[i].sku
							);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		}
	};

	if (products.length < 6) {
		return <Loader />;
	} else {
		return (
			<Productsdiv>
				<div className="product1">
					<Link to={"/product/" + products[0].id}>
						<img src={products[0].image} alt="" />
					</Link>
					<div className="details">
						<h3>
							<strong>{products[0].name}</strong>
						</h3>
						<p>{truncate(products[0].description, 90)}</p>
						<p>
							<strong>KES {products[0].price.toLocaleString()}</strong>
						</p>
						{stockQuantityFn(0)}
					</div>
				</div>
				<div className="product2">
					<Link to={"/product/" + products[1].id}>
						<img src={products[1].image} alt="" />
					</Link>
					<div className="details">
						<h3>
							<strong>{products[1].name}</strong>
						</h3>
						<p>{truncate(products[1].description, 90)}</p>
						<p>
							<strong>KES {products[1].price.toLocaleString()}</strong>
						</p>
						{stockQuantityFn(1)}
					</div>
				</div>
				<div className="product3">
					<Link to={"/product/" + products[2].id}>
						<img src={products[2].image} alt="" />
					</Link>
					<div className="details">
						<h3>
							<strong>{products[2].name}</strong>
						</h3>
						<p>{truncate(products[2].description, 90)}</p>
						<p>
							<strong>KES {products[2].price.toLocaleString()}</strong>
						</p>
						{stockQuantityFn(2)}
					</div>
				</div>
				<div className="product4">
					<Link to={"/product/" + products[3].id}>
						<img src={products[3].image} alt="" />
					</Link>
					<div className="details">
						<h3>
							<strong>{products[3].name}</strong>
						</h3>
						<p>{truncate(products[3].description, 90)}</p>
						<p>
							<strong>KES {products[3].price.toLocaleString()}</strong>
						</p>
						{stockQuantityFn(3)}
					</div>
				</div>
				<div className="product5">
					<Link to={"/product/" + products[4].id}>
						<img src={products[4].image} alt="" />
					</Link>
					<div className="details">
						<h3>
							<strong>{products[4].name}</strong>
						</h3>
						<p>{truncate(products[4].description, 90)}</p>
						<p>
							<strong>KES {products[4].price.toLocaleString()}</strong>
						</p>
						{stockQuantityFn(4)}
					</div>
				</div>
				<div className="product6">
					<Link to={"/product/" + products[5].id}>
						<img src={products[5].image} alt="" />
					</Link>
					<div className="details">
						<h3>
							<strong>{products[5].name}</strong>
						</h3>
						<p>{truncate(products[5].description, 90)}</p>
						<p>
							<strong>KES {products[5].price.toLocaleString()}</strong>
						</p>
						{stockQuantityFn(5)}
					</div>
				</div>
			</Productsdiv>
		);
	}
}

const Productsdiv = styled.div`
	margin-top: 10px;
	padding: 10px 30px 10px 30px;
	background-color: var(--secondary-color);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	div {
		background-color: white;
		color: var(--secondary-color);
		text-align: center;
		height: 350px;
		overflow: hidden;
		font-size: medium;
		border-radius: 1%;
	}
	p,
	h3 {
		margin-bottom: 2px;
		font-size: small;
		overflow: hidden;
		white-space: nowrap;
	}

	div > button {
		background-color: rgba(20, 20, 20, 0.5);
		border: 1px solid;
		border-color: yellow yellowgreen yellowgreen yellow;
		color: var(--primary-color);
		cursor: pointer;
		width: 100px;
		height: 30px;
		transition: 450ms;
		margin-top: 10px;
	}

	div > button:hover {
		transform: scale(1.05);
	}
	.product1,
	.product2 {
		flex: 1 1 35%;
	}
	.product3 {
		flex: 1 1 100%;
	}
	.product4,
	.product5,
	.product6 {
		flex: 1 1 25%;
	}
	img {
		object-fit: contain;
		width: 100%;
		height: 60%;
		transition: 450ms;
		cursor: pointer;
	}
	img:hover {
		transform: scale(1.03);
	}
	@media (max-width: 650px) {
		padding: 10px 10px 0 10px;
		gap: 3px;
		div {
			font-size: xx-small;
			p,
			h3 {
				margin-bottom: 3px;
				font-size: small;
			}
			button {
				width: 70px;
				height: 20px;
				font-size: smaller;
				margin-top: -10px;
			}
		}
		.product1,
		.product2,
		.product3,
		.product4,
		.product5,
		.product6 {
			flex: 1 1 100%;
			height: 200px;
		}
		.product6 {
			margin-bottom: 3px;
		}
		img {
			height: 40%;
			object-fit: contain;
		}
		h3 {
			font-size: small;
		}
	}

	@media (min-width: 651px) and (max-width: 1200px) {
		div {
			font-size: smaller;
			h3,
			p {
				font-size: small;
				margin-bottom: 3px;
			}
			button {
				width: 90px;
				height: 25px;
				font-size: smaller;
				margin-top: -5px !important;
			}
		}
		.product1,
		.product2,
		.product3,
		.product4,
		.product5,
		.product6 {
			flex: 1 1 40%;
			height: 250px;
		}
		img {
			height: 50%;
			object-fit: contain;
		}
	}
`;

export default Products;

import React from "react";
import styled from "styled-components";

function Banner() {
	return (
		<BannerDiv>
			<img
				src="https://res.cloudinary.com/hush-computing/image/upload/v1665140766/Banner/delivery_m8s8cy.jpg"
				alt=""
			/>
			<div>
				<p>
					<strong>Like what you see?</strong>
				</p>
				<p> {">"} Order online and we will deliver to your door</p>
				<p> {">"} Pay on delivery</p>
			</div>
		</BannerDiv>
	);
}

const BannerDiv = styled.div`
	height: 300px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px 30px 0 30px;
	position: relative;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}
	div {
		position: absolute;
		background-color: #e74c3c;
		color: white;
		padding: 10px;
		font-size: 17px;
	}
	@media (max-width: 650px) {
		height: 200px;
		padding: 10px 10px 0 10px;
		align-items: flex-end;
		img {
		}
		div {
			font-size: 10px;
			width: 90%;
			height: 90px;
		}
	}
`;

export default Banner;

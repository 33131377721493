import React, { useEffect, useState } from "react";
import logo from "../Images/Company/Hush Logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";
import { RiShoppingCart2Line } from "react-icons/ri";
import { IoPersonCircle } from "react-icons/io5";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineBars, AiOutlineClose } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar as NavBar } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import url from "../ServerConnection/config";
import { Tooltip } from "antd";

function Navbar() {
	const cartQuantity = useSelector((state) => state.cart.totalQuantity);
	const loginStatus = useSelector((state) => state.user.accessToken);
	const [show, setShow] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [categories, setCategories] = useState([]);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		axios
			.get(`${url}/categories/all`)
			.then((res) => {
				setCategories(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<NavBar className="navbar" expand="md">
			<Tooltip title="Home - Hush Computing" color="blue" placement="bottom">
				<NavBar.Brand>
					<Link to="/" className="navbar-logo">
						<img src={logo} alt="" className="company-logo" />
						<span className="company-name">Hush Computing</span>
						<span className="slogan">
							<em>More Tech, More Possibilities</em>
						</span>
					</Link>
				</NavBar.Brand>
			</Tooltip>
			<section className="navbar-mid-section">
				<NavBar.Toggle
					aria-controls="basic-navbar-nav"
					className="navbar-toggler"
				>
					<AiOutlineBars className="toggler" onClick={handleShow} />
				</NavBar.Toggle>
				<NavBar.Offcanvas
					id="basic-navbar-nav"
					placement="end"
					show={show}
					className="offcanvas"
					onHide={handleClose}
				>
					<Offcanvas.Header>
						<AiOutlineClose onClick={handleClose} className="close-btn" />
					</Offcanvas.Header>
					<Offcanvas.Body>
						<NavDropdown
							title="Categories"
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							onToggle={() => setIsClicked(!isClicked)}
							show={isHovered ? isHovered : isClicked}
						>
							<div className="drop">
								{categories?.map((category) => {
									return (
										<NavDropdown.Item className="drop-item" key={category.id}>
											<Link
												to={"/category/" + category.category}
												className="nav-link"
											>
												{category.category}
											</Link>
										</NavDropdown.Item>
									);
								})}
							</div>
						</NavDropdown>

						<span
							className="nav-link"
							onClick={() => {
								window.scrollTo(0, document.body.scrollHeight);
								handleClose();
							}}
						>
							About Us
						</span>
						<span
							className="nav-link"
							onClick={() => {
								window.scrollTo(0, document.body.scrollHeight);
								handleClose();
							}}
						>
							Contact Us
						</span>
						<Link to="/" className="nav-link">
							Blog
						</Link>
					</Offcanvas.Body>
				</NavBar.Offcanvas>
			</section>
			<section className="navbar-right-section">
				<Tooltip title="Home - Hush Computing" color="blue" placement="bottom">
					<Link to="/" className="home">
						<AiOutlineHome />
					</Link>
				</Tooltip>
				<Tooltip title="Search" color="blue" placement="bottom">
					<Link to="/search" className="search">
						<BiSearchAlt2 />
					</Link>
				</Tooltip>
				{loginStatus === null && (
					<Tooltip title="Login" color="blue" placement="bottom">
						<Link to="/login" className="login">
							<IoPersonCircle />
						</Link>
					</Tooltip>
				)}
				{loginStatus != null && (
					<Tooltip title="My Account" color="blue" placement="bottom">
						<Link to="/account" className="login">
							<IoPersonCircle />
						</Link>
					</Tooltip>
				)}
				<Tooltip title="Cart" color="blue" placement="bottom">
					<Link to="/checkout" className="checkout">
						<RiShoppingCart2Line />
						{cartQuantity > 0 && (
							<div className="cart-count">{cartQuantity}</div>
						)}
					</Link>
				</Tooltip>
			</section>
		</NavBar>
	);
}

export default Navbar;

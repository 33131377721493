import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Loader from "./Loader";
import url from "../ServerConnection/config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { cartActions } from "../App/cart-slice";
import "../index.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Tooltip } from "antd";

function MoreProducts() {
	const [products, setProducts] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		axios
			.get(`${url}/products/moreproducts`)
			.then((res) => {
				setProducts(res.data);
			})
			.catch((err) => {});
	}, []);
	const addNotification = (name) =>
		toast.success("Added " + name + " to cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "add-success-notification",
		});

	const addToCart = (name, id, price, image, sku) => {
		dispatch(
			cartActions.addToCart({
				name: name,
				id: id,
				price: price,
				image: image,
				sku: sku,
			})
		);
		addNotification(name);
	};

	const stockQuantityFn = (id, name, price, image, sku, stockQuantity) => {
		if (stockQuantity === 0) {
			return (
				<>
					<span style={{ color: "#e74c3c" }}>
						<strong>Out of Stock!</strong>
					</span>
				</>
			);
		} else if (stockQuantity >= 1 && stockQuantity <= 5) {
			return (
				<>
					<span style={{ color: "#e74c3c" }}>
						<strong>Only {stockQuantity} remaining!</strong>
					</span>
					<button
						onClick={() => {
							addToCart(name, id, price, image, sku);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		} else {
			return (
				<>
					<span style={{ color: "#07bc0c" }}>
						<strong>In Stock</strong>
					</span>
					<button
						onClick={() => {
							addToCart(name, id, price, image, sku);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		}
	};
	return (
		<AllProductsDiv>
			{products.length > 0 ? (
				<>
					<div className="topbar">
						<p style={{ marginTop: "15px" }}>More Products</p>
						<div>
							<Tooltip title="Scroll Left" color="blue">
								<span
									onClick={() => {
										return (document.getElementById("items").scrollLeft -= 200);
									}}
								>
									<IoIosArrowBack />
								</span>
							</Tooltip>
							<Tooltip title="Scroll Right" color="blue">
								<span
									onClick={() => {
										return (document.getElementById("items").scrollLeft += 200);
									}}
								>
									<IoIosArrowForward />
								</span>
							</Tooltip>
						</div>
					</div>
					<div className="items" id="items">
						{products?.map((product) => {
							return (
								<div className="product" key={product.id}>
									<Link to={"/product/" + product.id}>
										<img src={product.image} alt="" />
									</Link>
									<div className="details">
										<p>{product.name}</p>
										<span>
											<strong>KES {product.price.toLocaleString()}</strong>
										</span>
										{stockQuantityFn(
											product.id,
											product.name,
											product.price,
											product.image,
											product.sku,
											product.stockQuantity
										)}
									</div>
								</div>
							);
						})}
					</div>
				</>
			) : (
				<Loader />
			)}
		</AllProductsDiv>
	);
}

const AllProductsDiv = styled.div`
	height: 335px;
	background-color: var(--secondary-color);
	color: var(--primary-color);
	font-size: large;
	padding: 10px 30px 0 30px;
	margin-top: 10px;
	border: 1px solid blue;
	.topbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		div {
			display: flex;
			gap: 10px;
			span {
				background-color: white;
				color: #e74c3c;
				border-radius: 50%;
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0.7;
				cursor: pointer;
			}
			span:hover {
				opacity: 0.3;
			}
		}
	}
	.items {
		display: flex;
		width: 100%;
		overflow-x: scroll;
		scroll-behavior: smooth;
	}
	.items::-webkit-scrollbar {
		display: none;
	}
	.product {
		height: 250px;
		background-color: white;
		margin-right: 5px;
		min-width: 200px;
		overflow: hidden;
		border-radius: 3px;
		img {
			width: 100%;
			height: 50%;
			object-fit: contain;
		}
		.details {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			gap: 3px;
			font-size: small;
			color: black;
			overflow: hidden;
			white-space: nowrap;
			text-align: left;
			padding-left: 5px;
			p {
				margin: 0;
			}
			span {
				align-self: center;
			}
			button {
				align-self: center;
				background-color: rgba(20, 20, 20, 0.5);
				border: 1px solid;
				border-color: yellow yellowgreen yellowgreen yellow;
				color: var(--primary-color);
				cursor: pointer;
				transition: 450ms;
			}
			button:hover {
				transform: scale(1.05);
			}
		}
	}
`;

export default MoreProducts;

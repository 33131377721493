import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import url from "../ServerConnection/config";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { cartActions } from "../App/cart-slice";
import Loader from "./Loader";
import ScrollToTop from "../ScrollToTop";
import Banner from "./Banner";

function SingleCategory() {
	const dispatch = useDispatch();
	const [products, setProducts] = useState([]);
	const params = useParams();
	const customId = "error-id";
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get(`${url}/products/category/${params.category}`)
			.then((res) => {
				setProducts(res.data);
			})
			.catch((err) => {
				errorNotification(err.response.data);
				navigate("/");
			});
	}, [params.category, navigate]);

	const addNotification = (name) =>
		toast.success("Added " + name + " to cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "add-success-notification",
		});

	const errorNotification = (e) =>
		toast.error(e, {
			toastId: customId,
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});

	const addToCart = (name, id, price, image, sku) => {
		dispatch(
			cartActions.addToCart({
				name: name,
				id: id,
				price: price,
				image: image,
				sku: sku,
			})
		);
		addNotification(name);
	};

	function truncate(string, n) {
		return string?.length > n ? string.substring(0, n - 1) + "..." : string;
	}

	const stockQuantityFn = (id, name, price, image, sku, stockQuantity) => {
		if (stockQuantity === 0) {
			return (
				<>
					<p style={{ color: "#e74c3c" }}>
						<strong>Out of Stock!</strong>
					</p>
				</>
			);
		} else if (stockQuantity >= 1 && stockQuantity <= 5) {
			return (
				<>
					<p style={{ color: "#e74c3c" }}>
						<strong>Only {stockQuantity} remaining!</strong>
					</p>
					<button
						onClick={() => {
							addToCart(name, id, price, image, sku);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		} else {
			return (
				<>
					<p style={{ color: "#07bc0c" }}>
						<strong>In Stock</strong>
					</p>
					<button
						onClick={() => {
							addToCart(name, id, price, image, sku);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		}
	};

	if (products.length === 0) {
		return (
			<>
				<Loader />
				<ScrollToTop />
			</>
		);
	} else {
		return (
			<>
				<Productsdiv>
					<p className="number-of-products">
						{products?.length} product(s) found
					</p>
					{products?.map((product) => {
						return (
							<div className="product" key={product.id}>
								<Link to={"/product/" + product.id}>
									<img src={product.image} alt="" />
								</Link>
								<div className="details">
									<h3>
										<strong>{product.name}</strong>
									</h3>
									<p>{truncate(product.description, 90)}</p>
									<p>
										<strong>KES {product.price.toLocaleString()}</strong>
									</p>
									{stockQuantityFn(
										product.id,
										product.name,
										product.price,
										product.image,
										product.sku,
										product.stockQuantity
									)}
								</div>
							</div>
						);
					})}
					<ScrollToTop />
				</Productsdiv>
				<Banner />
			</>
		);
	}
}

const Productsdiv = styled.div`
	margin-top: 10px;
	padding: 10px 30px 10px 30px;
	background-color: var(--secondary-color);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	div {
		background-color: white;
		color: var(--secondary-color);
		text-align: center;
		height: 350px;
		overflow: hidden;
		font-size: medium;
		border-radius: 1%;
	}
	p,
	h3 {
		margin-bottom: 2px;
		font-size: small;
		overflow: hidden;
		white-space: nowrap;
	}

	div > button {
		background-color: rgba(20, 20, 20, 0.5);
		border: 1px solid;
		border-color: yellow yellowgreen yellowgreen yellow;
		color: var(--primary-color);
		cursor: pointer;
		width: 100px;
		height: 30px;
		transition: 450ms;
		margin-top: 10px;
	}

	div > button:hover {
		transform: scale(1.05);
	}
	.product {
		flex: 1 1 30%;
		padding-top: 10px;
	}
	.number-of-products {
		flex: 1 1 100%;
		color: var(--primary-color);
		font-size: medium;
	}
	img {
		object-fit: contain;
		width: 100%;
		height: 60%;
		transition: 450ms;
		cursor: pointer;
	}
	img:hover {
		transform: scale(1.03);
	}
	@media (max-width: 650px) {
		padding: 10px 10px 0 10px;
		gap: 3px;
		div {
			font-size: xx-small;
			p,
			h3 {
				margin-bottom: 3px;
				overflow: hidden;
				white-space: nowrap;
				font-size: small;
			}
			button {
				width: 70px;
				height: 20px;
				font-size: smaller;
				margin-top: -10px;
			}
		}
		.product {
			flex: 1 1 100%;
			height: 200px;
			margin-bottom: 10px;
		}
		img {
			height: 40%;
			object-fit: contain;
		}
		h3 {
			font-size: small;
		}
	}

	@media (min-width: 651px) and (max-width: 1200px) {
		div {
			font-size: smaller;
			h3,
			p {
				overflow: hidden;
				white-space: nowrap;
				font-size: small;
				margin-bottom: 3px;
			}
			button {
				width: 90px;
				height: 25px;
				font-size: smaller;
				margin-top: -5px !important;
			}
		}
		.product {
			flex: 1 1 40%;
			height: 250px;
		}
		img {
			height: 50%;
			object-fit: contain;
		}
	}
`;

export default SingleCategory;

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		userId: null,
		userEmail: null,
		accessToken: null,
		refreshToken: null,
	},
	reducers: {
		login(state, action) {
			state.userId = action.payload.id;
			state.userEmail = action.payload.email;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
		},
		logout(state) {
			state.userId = null;
			state.userEmail = null;
			state.accessToken = null;
			state.refreshToken = null;
		},
	},
});

export const userActions = userSlice.actions;
export default userSlice;

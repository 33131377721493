import React from "react";
import Products from "./Products";
import Slider from "./Slider";
import Banner from "./Banner";
import MoreProducts from "./MoreProducts";
import { motion } from "framer-motion";
import ScrollToTop from "../ScrollToTop";

function Home() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 1 } }}
			transition={{ duration: 0.5 }}
		>
			<Slider />
			<Products />
			<Banner />
			<MoreProducts />
			<ScrollToTop />
		</motion.div>
	);
}

export default Home;

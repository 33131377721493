import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../index.css";
import { Link } from "react-router-dom";
import { BsFacebook, BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import axios from "axios";
import url from "../ServerConnection/config";
import { Tooltip } from "antd";

function Footer() {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		axios
			.get(`${url}/categories/all`)
			.then((res) => {
				setCategories(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<FooterDiv>
			<section>
				<MainDiv>
					<MainLink to="/">
						<p>Hush Computing</p>
						<span>
							<em>More Tech, More Possibilities</em>
						</span>
					</MainLink>
					<SocialMedia>
						<Tooltip title="Facebook" color="#4267b2" placement="bottom">
							<SocialIcons to={"/"}>
								<BsFacebook />
							</SocialIcons>
						</Tooltip>
						<Tooltip title="Twitter" color="#1d9bf0" placement="bottom">
							<SocialIcons to={"/"}>
								<BsTwitter />
							</SocialIcons>
						</Tooltip>
						<Tooltip title="Instagram" color="#c13584" placement="bottom">
							<SocialIcons to={"/"}>
								<BsInstagram />
							</SocialIcons>
						</Tooltip>
						<Tooltip title="Whatsapp" color="#25d366" placement="bottom">
							<SocialIcons to={"/"}>
								<BsWhatsapp />
							</SocialIcons>
						</Tooltip>
					</SocialMedia>
					<Copyright>
						<span>&copy; 2022. All Rights Reserved.</span>
					</Copyright>
				</MainDiv>
				<Div>
					<h4>Categories</h4>
					{categories?.map((category) => {
						return (
							<FooterLink
								to={"/category/" + category.category}
								key={category.id}
							>
								{category.category}
							</FooterLink>
						);
					})}
				</Div>
				<Div>
					<h4>About Us</h4>
					<h5>Contacts</h5>
					<p>+254719738483</p>
					<p>muchinaelias@gmail.com</p>
					<h5>Legal</h5>
					<FooterLink to={"/policy"}>
						Shipping, Returns, & Privacy Policy
					</FooterLink>
				</Div>
			</section>
		</FooterDiv>
	);
}

const FooterDiv = styled.footer`
	background-color: var(--secondary-color);
	color: var(--primary-color);
	margin-top: 10px;
	padding: 50px 30px 0 30px;
	min-height: 320px;
	box-sizing: border-box;
	display: flex;
	section {
		margin-right: auto;
		margin-left: auto;
		width: 55%;
		display: flex;
		justify-content: center;
		gap: 2px;
		h4 {
			font-size: medium;
			color: white;
		}
		h5 {
			font-size: small;
			color: white;
			margin-top: 10px;
		}
		@media (max-width: 1000px) {
			width: 80%;
		}
		@media (max-width: 650px) {
			width: 100%;
		}
		@media (max-width: 520px) {
			flex-direction: column;
			align-items: center;
			gap: 15px;
		}
	}
`;

const Div = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: 10px;
	p {
		font-size: small;
		padding: 0;
		margin: 0;
	}
	@media (max-width: 520px) {
		text-align: center;
		justify-content: center;
		flex: 1;
		width: 70%;
	}
`;

const MainDiv = styled.div`
	flex: 2;
	justify-self: flex-start;
	@media (max-width: 520px) {
		flex: 1;
		width: 70%;
		order: 1;
	}
`;

const MainLink = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--primary-color);
	text-decoration: none;
	width: max-content;
	margin-right: auto;
	margin-left: auto;
	:hover {
		color: var(--primary-color);
	}
	p {
		margin-bottom: 1px;
		font-size: large;
	}
	span {
		font-size: small;
		margin-top: 0;
	}
`;

const SocialMedia = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
	gap: 10px;
	font-size: large;
`;

const SocialIcons = styled(Link)`
	text-decoration: none;
	color: var(--primary-color);
	transition: 450ms;
	:hover {
		transform: scale(1.05);
	}
	:nth-child(1) {
		color: #4267b2;
	}
	:nth-child(2) {
		color: #1d9bf0;
	}
	:nth-child(3) {
		color: #c13584;
	}
	:nth-child(4) {
		color: #25d366;
	}
`;

const FooterLink = styled(Link)`
	text-decoration: none;
	font-size: small;
	color: var(--primary-color);
	width: max-content;
	:hover {
		color: var(--primary-color);
		opacity: 0.8;
		text-decoration: underline;
	}
	@media (max-width: 520px) {
		align-self: center;
	}
`;

const Copyright = styled.div`
	font-size: small;
	margin-top: 30px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: max-content;
	p {
		font-size: small;
	}
`;

export default Footer;

import React, { useEffect, useState } from "react";
import "./Slider.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import url from "../ServerConnection/config";

function Slider() {
	const [slider, setSlider] = useState([]);

	useEffect(() => {
		axios.get(`${url}/banner`).then((res) => {
			setSlider(res.data);
		});
	}, []);

	if (slider.length < 5) {
		return (
			<div className="loading-div">
				<div className="loading">
					<span>Loading</span>
				</div>
			</div>
		);
	} else {
		return (
			<>
				<Carousel
					autoPlay={true}
					emulateTouch={true}
					infiniteLoop={true}
					interval={7000}
					showStatus={false}
					showThumbs={false}
					transitionTime={2000}
					dynamicHeight={false}
					stopOnHover={false}
					className="slider"
				>
					{slider?.slice(0, 5)?.map((item) => (
						<div key={item.id} className="slider-div">
							<img src={item.image} alt="" className="slider-image" />
						</div>
					))}
				</Carousel>
				<div className="slider-info">
					<h3 className="slider-text">
						At Hush Computing, we believe that with more tech, there are more
						possibilities...
					</h3>
					<button className="shop-btn" onClick={() => window.scrollTo(0, 390)}>
						Explore Now
					</button>
				</div>
			</>
		);
	}
}

export default Slider;

import React, { useEffect, useState } from "react";
import logo from "../Images/Company/Hush Logo.png";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../index.css";
import { BiLogIn } from "react-icons/bi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { userActions } from "../App/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import bg from "../Images/photo-1415356838286-df6fd593e8b3.png";
import { toast } from "react-toastify";
import axios from "axios";
import url from "../ServerConnection/config";

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordType, setPasswordType] = useState("password");

	const userId = useSelector((state) => state.user.userId);
	useEffect(() => {
		userId && navigate("/");
	}, [userId, navigate]);

	const toastId = React.useRef(null);

	const successNotification = () =>
		toast.success("Welcome back, " + email, {
			toastId: toastId,
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "success-notification",
		});

	const errorNotification = (e) =>
		toast.error(e, {
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});

	const togglePassword = (e) => {
		e.preventDefault();
		if (passwordType === "password") {
			setPasswordType("text");
			setTimeout(() => {
				setPasswordType("password");
			}, 1500);
		} else {
			setPasswordType("password");
		}
	};

	const signIn = (e) => {
		e.preventDefault();

		axios
			.post(`${url}/user/login`, { email: email, password: password })
			.then((res) => {
				// Signed in
				successNotification();
				const id = res.data.id;
				const accessToken = res.data.accessToken;
				const refreshToken = res.data.refreshToken;
				dispatch(
					userActions.login({
						id: id,
						email: email,
						accessToken: accessToken,
						refreshToken: refreshToken,
					})
				);
				navigate("/");
			})
			.catch((error) => {
				errorNotification(error.response.data);
			});
	};

	return (
		<Page>
			<MainContainer
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0, transition: { duration: 1 } }}
				transition={{ duration: 1.5 }}
			>
				<LogoContainer>
					<TopLink to="/">
						<img src={logo} alt="" />
						<p>Hush Computing</p>
						<span>
							<em>More Tech, More Possibilities</em>
						</span>
					</TopLink>
				</LogoContainer>

				<LoginContainer>
					<p>
						<strong>Log in to continue shopping on Hush Computing</strong>
					</p>
					<form>
						<label htmlFor="email">Email</label>
						<input
							type="email"
							autoComplete="on"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label htmlFor="password">Password</label>
						<div>
							<input
								type={passwordType}
								autoComplete="on"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<EyeIcon onClick={(e) => togglePassword(e)} tabIndex="-1">
								{passwordType === "password" && password !== "" ? (
									<AiOutlineEye />
								) : (
									<AiOutlineEyeInvisible />
								)}
							</EyeIcon>
						</div>
						<SubmitBtn type="submit" onClick={signIn}>
							<BiLogIn />
						</SubmitBtn>
						<p>
							By logging in, you are agreeing to Hush Computing's Terms of
							Service.
						</p>
					</form>
					<BottomContainer>
						<p>
							Forgot Password? <StyledLink to="/"> Reset</StyledLink>
						</p>
						<p>
							Don't have an account?
							<StyledLink to="/register"> Sign up</StyledLink>
						</p>
					</BottomContainer>
				</LoginContainer>
			</MainContainer>
		</Page>
	);
}

const Page = styled.div`
	background: linear-gradient(-45deg, var(--secondary-color), #767676);
	height: 100vh;
	top: 0;
	width: 100vw;
	padding-top: 5vh;
`;

const MainContainer = styled(motion.div)`
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 90vh;
	width: 60%;
	margin: auto;
	@media (max-width: 768px) {
		flex-direction: column;
		width: 80%;
	}
`;

const LogoContainer = styled.div`
	margin-right: auto;
	margin-left: auto;
	color: var(--primary-color);
	flex: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #767676;
	border-right: none;
	background-image: url(${bg});
	background-repeat: no-repeat;
	background-size: cover;
	@media (max-width: 768px) {
		width: 100%;
		border: 1px solid #767676;
		border-bottom: none;
	}
`;

const TopLink = styled(Link)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: var(--primary-color);
	text-decoration: none;
	:hover {
		color: var(--primary-color);
	}
	img {
		height: 50px;
		width: 100%;
		object-fit: contain;
	}
	p {
		margin-bottom: 1px;
		font-size: large;
	}
	span {
		font-size: small;
		margin-top: 0;
	}
`;

const LoginContainer = styled.div`
	flex: 1;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	color: var(--primary-color);
	border: 1px solid #767676;
	border-left: none;
	padding: 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 768px) {
		width: 100%;
		border: 1px solid #767676;
		border-top: none;
		form {
			gap: 5px;
			font-size: smaller;
		}
		p {
			font-size: smaller;
		}
	}
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		input {
			width: 100%;
			position: relative;
			height: 30px;
			outline: none;
			border: none;
			color: #000;
		}
		p {
			font-size: small;
		}
	}
`;

const EyeIcon = styled.button`
	border: none;
	position: absolute;
	margin-left: -30px;
	max-height: 20px;
	background-color: transparent;
	color: #000;
`;

const SubmitBtn = styled.button`
	background-color: #418914;
	color: white;
	border: 1px solid;
	border-color: #418914;
	border-radius: 3px;
	transition: 450ms;
	margin-top: 10px;
	font-size: larger;
	vertical-align: middle;
	outline: none;
	:hover,
	:focus {
		transform: scale(1.05);
	}
`;

const BottomContainer = styled.div`
	font-size: small;
	p {
		margin-top: -10px;
	}
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	font-size: medium;
	@media (max-width: 576px) {
		font-size: small;
	}
`;

export default Login;

import React from "react";
import axios from "axios";
import url from "../ServerConnection/config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userActions } from "../App/user-slice";
import { toast } from "react-toastify";

function RefreshToken({ setLoadRefreshComponent }) {
	let accessToken = useSelector((state) => state.user.accessToken);
	let refreshToken = useSelector((state) => state.user.refreshToken);
	let userId = useSelector((state) => state.user.userId);
	let email = useSelector((state) => state.user.userEmail);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const customId = "custom-id";

	const refreshFn = () => {
		axios
			.post(`${url}/user/refresh`, {
				refreshToken: refreshToken,
				id: userId,
				email: email,
			})
			.then((res) => {
				accessToken = res.data.accessToken;
				refreshToken = res.data.refreshToken;
				userId = res.data.id;
				email = res.data.email;
				dispatch(
					userActions.login({
						id: userId,
						accessToken: accessToken,
						refreshToken: refreshToken,
						email: email,
					})
				);
				setLoadRefreshComponent(false);
			})
			.catch(() => {
				errorNotification("Your session expired. Login again");
				axios.post(`${url}/user/logout`, { refreshToken: refreshToken });
				dispatch(userActions.logout());
				navigate("/login");
			});
	};

	const errorNotification = (e) =>
		toast.error(e, {
			toastId: customId,
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});

	return (
		<div>
			Refreshing Token...
			{refreshFn()}
		</div>
	);
}

export default RefreshToken;

import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AnimatedPages from "./AnimatedPages";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-modal/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function App() {
	return (
		<>
			<Router>
				<div className="App">
					<AnimatedPages />
				</div>
			</Router>
			<ToastContainer transition={Zoom} pauseOnFocusLoss={false} />
		</>
	);
}

export default App;

import React from "react";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import { Routes, Route, useLocation } from "react-router-dom";
import Checkout from "./Components/Checkout";
import Login from "./Components/Login";
import Account from "./Components/Account";
import Register from "./Components/Register";
import Footer from "./Components/Footer";
import { AnimatePresence } from "framer-motion";
import SingleProduct from "./Components/SingleProduct";
import SingleCategory from "./Components/SingleCategory";
import Search from "./Components/Search";
import Policy from "./Components/Policy";

function AnimatedPages() {
	const location = useLocation();
	return (
		<AnimatePresence exitBeforeEnter>
			<Routes location={location} key={location.key}>
				<Route exact path="/login" element={<Login />} />
				<Route
					exact
					path="/account"
					element={[<Navbar />, <Account />, <Footer />]}
				/>
				<Route exact path="/register" element={<Register />} />
				<Route
					exact
					path="/checkout"
					element={[<Navbar />, <Checkout />, <Footer />]}
				/>
				<Route
					exact
					path="/product/:id"
					element={[<Navbar />, <SingleProduct />, <Footer />]}
				/>
				<Route
					exact
					path="/category/:category"
					element={[<Navbar />, <SingleCategory />, <Footer />]}
				/>
				<Route
					exact
					path="/search"
					element={[<Navbar />, <Search />, <Footer />]}
				/>
				<Route
					exact
					path="/policy"
					element={[<Navbar />, <Policy />, <Footer />]}
				/>
				<Route exact path="/" element={[<Navbar />, <Home />, <Footer />]} />
				<Route exact path="*" element={[<Navbar />, <Home />, <Footer />]} />
			</Routes>
		</AnimatePresence>
	);
}

export default AnimatedPages;

import React, { useEffect, useState } from "react";
import { userActions } from "../App/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Layout, Menu, Table } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import ScrollToTop from "../ScrollToTop";
import Loader from "./Loader";
import styled from "styled-components";
import "../index.css";
import { IoPersonCircle } from "react-icons/io5";
import { RiShoppingBasketFill } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import axios from "axios";
import url from "../ServerConnection/config";
import RefreshToken from "./RefreshToken";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import useScreenType from "react-screentype-hook";
import { toast } from "react-toastify";
import { Breadcrumb } from "antd";

const { Header, Content, Sider } = Layout;

function Account() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userId = useSelector((state) => state.user.userId);
	let accessToken = useSelector((state) => state.user.accessToken);
	let refreshToken = useSelector((state) => state.user.refreshToken);
	const [loadRefreshComponent, setLoadRefreshComponent] = useState(false);
	const [isActive, setIsActive] = useState("1");
	const [orderDetails, setOrderDetails] = useState([]);
	const [collapsed, setCollapsed] = useState(false);
	const [orderModal, setOrderModal] = useState(false);
	const [record, setRecord] = useState(0);
	const [tableSize, setTableSize] = useState("middle");
	const [userDetails, setUserDetails] = useState({});
	const closeOrderModal = () => setOrderModal(false);
	const screenType = useScreenType();
	const toastId = "toast-id";

	useEffect(() => {
		axios
			.post(
				`${url}/orders/user`,
				{
					userId: userId,
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
			.then((res) => {
				res.data.map((order) => {
					order.key = order.id;
					order.createdAt = moment(order.createdAt).format("DD/MM/YYYY");
					return <></>;
				});

				setOrderDetails(res.data);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setLoadRefreshComponent(true);
				}
			});
	}, [accessToken, userId]);

	useEffect(() => {
		if (screenType.isMobile) {
			setTableSize("small");
		} else {
			setTableSize("middle");
		}
	}, [screenType]);

	useEffect(() => {
		if (userId !== null) {
			axios
				.post(
					`${url}/user/userdetails`,
					{ userId: userId },
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then((res) => {
					setUserDetails(res.data);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						setLoadRefreshComponent(true);
					}
				});
		}
	}, [accessToken, userId]);

	if (!accessToken) {
		navigate("/");
	}

	const successNotification = (e) =>
		toast.success(e, {
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.TOP_CENTER,
			className: "add-success-notification",
		});

	const errorNotification = (e) =>
		toast.error(e, {
			toastId: toastId,
			autoClose: 4000,
			pauseOnHover: false,
			closeButton: false,
			position: toast.POSITION.TOP_CENTER,
			className: "error-notification",
		});

	const logoff = () => {
		axios.post(`${url}/user/logout`, { refreshToken: refreshToken });
		dispatch(userActions.logout());
		navigate("/");
	};

	const columns = [
		{
			title: "Order ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Date of Order",
			dataIndex: "createdAt",
			key: "orderdate",
		},
		{
			title: "Order Total",
			key: "orderdate",
			render: (_, { total }) => {
				return <span>KES {total.toLocaleString()}</span>;
			},
			responsive: ["md"],
		},
		{
			title: "Address, City",
			key: "address",
			render: (_, { userAddress, userCity }) => {
				return <span>{userAddress + ", " + userCity}</span>;
			},
			responsive: ["md"],
		},
		{
			title: "Delivery Status",
			dataIndex: "deliveryStatus",
			key: "deliveryStatus",
			render: (_, { deliveryStatus }) => {
				if (deliveryStatus === "Pending Delivery") {
					return <span style={{ color: "#e74c3c" }}>{deliveryStatus}</span>;
				} else {
					return <span style={{ color: "#07bc0c" }}>{deliveryStatus}</span>;
				}
			},
		},
		{
			title: "Payment Status",
			dataIndex: "payStatus",
			key: "paystatus",
			render: (_, { payStatus }) => {
				if (payStatus === "Not Paid") {
					return <span style={{ color: "#e74c3c" }}>{payStatus}</span>;
				} else {
					return <span style={{ color: "#07bc0c" }}>{payStatus}</span>;
				}
			},
		},
	];

	const handleOpenOrderModal = (index) => {
		setOrderModal(true);
		setRecord(index);
	};

	const handleContactInformation = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const email = formData.get("email");
		const firstName = formData.get("firstName");
		const lastName = formData.get("lastName");
		const address = formData.get("address");
		const city = formData.get("city");
		const mobileNumber = formData.get("mobileNumber");
		if (
			!email ||
			!firstName ||
			!lastName ||
			!address ||
			!city ||
			!mobileNumber
		) {
			errorNotification("Please fill all the fields!");
			return;
		} else if (mobileNumber.length < 10 || mobileNumber.length > 13) {
			errorNotification("Please enter a valid mobile number!");
			return;
		} else if (
			firstName !== userDetails?.firstName ||
			lastName !== userDetails?.lastName ||
			address !== userDetails?.address ||
			city !== userDetails?.city ||
			mobileNumber !== userDetails?.mobileNumber
		) {
			axios
				.post(
					`${url}/user/updateuserdetails`,
					{
						userId: userId,
						email: email,
						firstName: firstName,
						lastName: lastName,
						address: address,
						city: city,
						mobileNumber: mobileNumber,
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				)
				.then((res) => {
					setUserDetails(res.data);
					successNotification("Successfully saved your details!");
				})
				.catch((err) => {
					if (err.response.status === 401) {
						setLoadRefreshComponent(true);
					} else {
						errorNotification(err.response.data);
					}
				});
		}
	};

	return (
		<Layout style={{ minHeight: "500px", marginTop: "10px" }}>
			<HeaderOne>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to="/">Home</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>My Account</Breadcrumb.Item>
				</Breadcrumb>
				{React.createElement(
					collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
					{
						className: "trigger",
						onClick: () => setCollapsed(!collapsed),
					}
				)}
			</HeaderOne>
			<LayoutSecondary>
				<Sider
					collapsible
					collapsed={collapsed}
					trigger={null}
					style={{ backgroundColor: "#ededed" }}
					collapsedWidth="50"
					width="95"
				>
					<Menu
						mode="inline"
						defaultSelectedKeys={["1"]}
						inlineIndent={3}
						onSelect={({ key }) => setIsActive(key)}
						style={{
							backgroundColor: "#ededed",
							padding: "0 10px 0 0",
							fontSize: "small",
						}}
						items={[
							{
								key: "1",
								icon: <IoPersonCircle />,
								label: "Details",
							},
							{
								key: "2",
								icon: <RiShoppingBasketFill />,
								label: "Orders",
							},
							{
								key: "3",
								icon: <BiLogOut />,
								label: "Logout",
							},
						]}
					/>
				</Sider>
				<Content
					style={{
						borderRadius: "5px",
						backgroundColor: "#fff",
					}}
				>
					{isActive === "1" ? (
						Object.keys(userDetails).length > 0 ? (
							<FormContainer>
								<form onSubmit={handleContactInformation}>
									<h5>My Details</h5>
									<input
										type="email"
										placeholder="Email"
										value={userDetails?.email}
										name="email"
										readOnly
									/>
									<section>
										<input
											type="text"
											placeholder="First Name"
											defaultValue={userDetails?.firstName}
											name="firstName"
											required
										/>
										<input
											type="text"
											placeholder="Last Name"
											defaultValue={userDetails?.lastName}
											name="lastName"
											required
										/>
									</section>
									<input
										type="text"
										placeholder="Phone"
										defaultValue={userDetails?.mobileNumber}
										name="mobileNumber"
										required
									/>
									<select>
										<option name="country" value="Kenya">
											Kenya
										</option>
									</select>
									<input
										type="text"
										placeholder="City/Town"
										defaultValue={userDetails?.city}
										name="city"
										required
									/>
									<input
										type="text"
										placeholder="Address"
										defaultValue={userDetails?.address}
										name="address"
										required
									/>
									<section>
										<span>
											Want to change your details? Enter all details and click
											on save button
										</span>
										<button type="submit">Save</button>
									</section>
								</form>
							</FormContainer>
						) : (
							<p style={{ marginTop: "30px", marginLeft: "10px" }}>
								<Loader />
							</p>
						)
					) : null}

					{isActive === "2" ? (
						orderDetails.length > 0 ? (
							<Table
								columns={columns}
								dataSource={orderDetails}
								pagination={false}
								size={tableSize}
								onRow={(record, index) => {
									return { onClick: () => handleOpenOrderModal(index) };
								}}
								style={{
									fontSize: "smaller",
								}}
							/>
						) : (
							<p style={{ marginTop: "10px", marginLeft: "10px" }}>
								You have no orders yet
							</p>
						)
					) : null}

					{isActive === "3" ? logoff() : null}
				</Content>
			</LayoutSecondary>
			<Modal
				open={orderModal}
				onClose={closeOrderModal}
				center
				animationDuration={0}
				styles={{ modal: { width: "90%" } }}
			>
				<h4>Order Items</h4>
				<div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: "10px",
							textAlign: "center",
							fontWeight: "600",
							marginBottom: "10PX",
							backgroundColor: "#afe1af",
							height: "60px",
						}}
					>
						<span style={{ width: "23%" }}>Product Image</span>
						<span style={{ width: "23%" }}>Product Name</span>
						<span style={{ width: "23%" }}>Product Price</span>
						<span style={{ width: "23%" }}>Product Quantity</span>
					</div>
					{orderDetails.length > 0
						? orderDetails[record].OrderItems.map((order) => {
								return (
									<div
										key={order.productId}
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											gap: "10px",
											textAlign: "center",
											marginBottom: "10px",
										}}
									>
										<span style={{ width: "23%" }}>
											<Link to={`/product/${order.productId}`}>
												<img
													src={order.productImage}
													alt=""
													style={{ width: "50px", height: "auto" }}
												/>
											</Link>
										</span>
										<span style={{ width: "23%" }}>
											<Link
												to={`/product/${order.productId}`}
												style={{ color: "#000" }}
											>
												{order.productName}
											</Link>
										</span>
										<span style={{ width: "23%" }}>
											KES {order.price.toLocaleString()}
										</span>
										<span style={{ width: "23%" }}>{order.quantity}</span>
									</div>
								);
						  })
						: null}
				</div>
			</Modal>
			{loadRefreshComponent ? (
				<RefreshToken setLoadRefreshComponent={setLoadRefreshComponent} />
			) : null}
			<ScrollToTop />
		</Layout>
	);
}

const HeaderOne = styled(Header)`
	background-color: #ededed;
	padding: 0 30px;
	margin: 0;
	font-size: 20px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	.trigger {
		cursor: pointer;
		transition: color 0.3s;
		color: var(--secondary-color);
		margin-left: 10px;
	}
	.trigger:hover {
		color: #1890ff;
	}
	@media (max-width: 500px) {
		padding: 0 10px;
	}
`;

const LayoutSecondary = styled(Layout)`
	background-color: #ededed;
	padding: 10px 30px 10px 30px;
	@media (max-width: 500px) {
		padding: 2px;
		overflow: hidden;
	}
`;

const FormContainer = styled.div`
	padding: 10px 30px 10px 30px;
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 80%;
		section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			input {
				width: 49%;
			}
		}
		input,
		select {
			width: 100%;
			outline: none;
			transition: all 0.25s ease-in-out;
			-webkit-transition: all 0.25s ease-in-out;
			-moz-transition: all 0.25s ease-in-out;
			border-radius: 3px;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border: 1px solid rgba(0, 0, 0, 0.2);
		}
		input:focus,
		select:focus {
			box-shadow: 0 0 5px rgba(118, 118, 118, 0.4);
			-webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.4);
			-moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.4);
			border: 1px solid rgba(118, 118, 118, 0.4);
		}
		span {
			color: #e74c3c;
		}
		button {
			background-color: #418914;
			color: white;
			border: 1px solid;
			border-color: #418914;
			border-radius: 3px;
			transition: 450ms;
			outline: none;
			width: 50%;
			height: 40px;
		}
		button:hover,
		button:focus {
			transform: scale(1.01);
		}
		@media (max-width: 768px) {
			margin-left: auto;
			margin-right: auto;
			font-size: small;
			h5 {
				font-size: small;
			}
		}
	}
	@media (max-width: 768px) {
		padding: 10px 10px 10px 10px;
		form {
			width: 90%;
			section {
				flex-direction: column;
				gap: 10px;
				input {
					width: 100%;
				}
			}
			button {
				width: 100%;
			}
		}
	}
`;

export default Account;

import { React } from "react";
import BounceLoader from "react-spinners/BounceLoader";

function Loader() {
	const override = {
		display: "block",
		margin: "10px auto",
	};
	return <BounceLoader color="#3498db" cssOverride={override} />;
}

export default Loader;

import React, { useEffect, useState } from "react";
import "../index.css";
import { BiSearchAlt2 } from "react-icons/bi";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { cartActions } from "../App/cart-slice";
import axios from "axios";
import url from "../ServerConnection/config";
import Loader from "./Loader";
import ScrollToTop from "../ScrollToTop";
import Banner from "./Banner";
import { Tooltip } from "antd";

function Search() {
	const [products, setProducts] = useState([]);
	const [searchWord, setSearchWord] = useState("");
	const [topProducts, setTopProducts] = useState([]);
	const [searchBtn, setSearchBtn] = useState("");
	const [message, setMessage] = useState(
		"Type in the space above to find products"
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (searchWord !== "") {
			axios
				.get(`${url}/search/products?search=${searchWord}`)
				.then((res) => {
					setProducts(res.data);
				})
				.catch(() => {
					setMessage("No results found. Try different words!");
				});
		} else {
			setProducts([]);
			setMessage("Type in the space above to search products");
		}
	}, [searchWord, searchBtn]);

	useEffect(() => {
		axios
			.get(`${url}/products/home`)
			.then((res) => {
				setTopProducts(res.data);
			})
			.catch((err) => {});
	}, []);

	const addNotification = (name) =>
		toast.success("Added " + name + " to cart", {
			autoClose: 2000,
			pauseOnHover: false,
			closeButton: false,
			hideProgressBar: true,
			position: toast.POSITION.BOTTOM_RIGHT,
			className: "add-success-notification",
		});

	const addToCart = (name, id, price, image, sku) => {
		dispatch(
			cartActions.addToCart({
				name: name,
				id: id,
				price: price,
				image: image,
				sku: sku,
			})
		);
		addNotification(name);
	};

	function truncate(string, n) {
		return string?.length > n ? string.substring(0, n - 1) + "..." : string;
	}

	const stockQuantityFn = (id, name, price, image, sku, stockQuantity) => {
		if (stockQuantity === 0) {
			return (
				<>
					<p style={{ color: "#e74c3c" }}>
						<strong>Out of Stock!</strong>
					</p>
				</>
			);
		} else if (stockQuantity >= 1 && stockQuantity <= 5) {
			return (
				<>
					<p style={{ color: "#e74c3c" }}>
						<strong>Only {stockQuantity} remaining!</strong>
					</p>
					<button
						onClick={() => {
							addToCart(name, id, price, image, sku);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		} else {
			return (
				<>
					<p style={{ color: "#07bc0c" }}>
						<strong>In Stock</strong>
					</p>
					<button
						onClick={() => {
							addToCart(name, id, price, image, sku);
						}}
					>
						Add to Cart
					</button>
				</>
			);
		}
	};

	return (
		<>
			<SearchPage>
				<div className="searchbar">
					<input
						value={searchWord}
						onChange={(e) => setSearchWord(e.target.value)}
					/>
					{searchWord !== "" && (
						<button
							onClick={() => {
								setSearchWord("");
								setProducts([]);
							}}
						>
							X
						</button>
					)}
					<Tooltip title="Search" color="blue" placement="bottom">
						<span onClick={() => setSearchBtn(searchWord)}>
							<BiSearchAlt2 />
						</span>
					</Tooltip>
				</div>
				<div className="searchresults">
					<p className="number-of-products">
						{products.length > 0
							? products?.length + " product(s) found"
							: null}
					</p>
					{products.length > 0 && searchWord !== "" ? (
						products?.map((product) => {
							return (
								<div className="product" key={product.id}>
									<Link to={"/product/" + product.id}>
										<img src={product.image} alt="" />
									</Link>
									<div className="details">
										<h3>
											<strong>{product.name}</strong>
										</h3>
										<p>{truncate(product.description, 90)}</p>
										<p>
											<strong>KES {product.price.toLocaleString()}</strong>
										</p>
										{stockQuantityFn(
											product.id,
											product.name,
											product.price,
											product.image,
											product.sku,
											product.stockQuantity
										)}
									</div>
								</div>
							);
						})
					) : (
						<p style={{ marginTop: "-10px", color: "white" }}>{message}</p>
					)}
					{topProducts.length > 0 ? (
						<>
							<p style={{ marginTop: "15px" }}>Today's Top Products</p>
							{topProducts?.map((product) => {
								return (
									<div className="product" key={product.id}>
										<Link to={"/product/" + product.id}>
											<img src={product.image} alt="" />
										</Link>
										<div className="details">
											<h3>
												<strong>{product.name}</strong>
											</h3>
											<p>{truncate(product.description, 90)}</p>
											<p>
												<strong>KES {product.price.toLocaleString()}</strong>
											</p>
											{stockQuantityFn(
												product.id,
												product.name,
												product.price,
												product.image,
												product.sku,
												product.stockQuantity
											)}
										</div>
									</div>
								);
							})}
						</>
					) : (
						<Loader />
					)}
				</div>
				<ScrollToTop />
			</SearchPage>
			<Banner />
		</>
	);
}

const SearchPage = styled.div`
	margin-top: 10px;
	padding: 20px 30px 10px 30px;
	min-height: 80vh;
	background-color: var(--secondary-color);
	.searchbar {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0;
		button {
			height: 40px;
			border: none;
			outline: none;
			background-color: white;
			font-weight: 700;
		}
		span {
			height: 40px;
			width: 40px;
			border: none;
			border-left: 1px solid var(--secondary-color);
			outline: none;
			font-size: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background-color: white;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		input {
			border: none;
			outline: var(--primary-color);
			width: 60%;
			height: 40px;
			font-size: medium;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			@media (max-width: 650px) {
				width: 90%;
			}
		}
	}
	.searchresults {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		width: 100%;
		overflow: hidden;
		box-sizing: border-box;
		color: var(--primary-color);
		margin-top: 10px;
		font-size: 20px;
		p {
			flex: 1 1 100%;
			font-size: medium;
		}
		div {
			padding-top: 2px;
			background-color: white;
			color: var(--secondary-color);
			text-align: center;
			height: 350px;
			overflow: hidden;
			font-size: medium;
			border-radius: 1%;
			flex: 1 1 30%;
			img {
				object-fit: contain;
				width: 100%;
				height: 60%;
				transition: 450ms;
				cursor: pointer;
			}
			img:hover {
				transform: scale(1.03);
			}
			p,
			h3 {
				margin-bottom: 2px;
				font-size: small;
				overflow: hidden;
				white-space: nowrap;
			}
			button {
				background-color: rgba(20, 20, 20, 0.5);
				border: 1px solid;
				border-color: yellow yellowgreen yellowgreen yellow;
				color: var(--primary-color);
				cursor: pointer;
				width: 100px;
				height: 30px;
				transition: 450ms;
				margin-top: 10px;
			}
			button:hover {
				transform: scale(1.05);
			}
		}
		@media (max-width: 1200px) {
			div {
				height: 250px;
				font-size: small;
				flex: 1 1 40%;
				img {
					height: 50%;
				}
				p,
				h3 {
					margin-bottom: 2px;
					font-size: smaller;
				}
				button {
					width: 90px;
					height: 25px;
					font-size: smaller;
					margin-top: -5px !important;
				}
			}
		}
		@media (max-width: 650px) {
			div {
				padding-top: 10px;
				flex: 1 1 100%;
				height: 200px;
				img {
					height: 40%;
				}
			}
			p {
				font-size: small;
			}
		}
	}
	@media (max-width: 650px) {
		padding: 20px 10px 10px 10px;
		font-size: small;
	}
`;

export default Search;
